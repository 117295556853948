<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Requisition List"
                   @onClickAddNewButton="onClickAddNewButton"/>

      <RequisitionSearchInfo @onClickGo="getRequisitionRegisters"/>

    </div>

    <RequisitionRegisterTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import RequisitionRegisterTable from '@/components/molecule/procurement/requisition/register/RequisitionRegisterTable'
import Pagination from '@/components/atom/Pagination'
import RequisitionSearchInfo from "@/components/molecule/procurement/requisition/RequisitionSearchInfo";

function onClickAddNewButton() {
  //todo
}

function getRequisitionRegisters() {
  //todo
}

function onPageChange() {
  //todo
}

</script>
