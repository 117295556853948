<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Requisition Name</th>
          <th>Requisition Code</th>
          <th>Requisition No</th>
          <th>Project</th>
          <th>Department</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
          <td>#RQ4545</td>
          <td></td>
          <td></td>
          <td>Department</td>
          <td>Active</td>
        </tr>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
